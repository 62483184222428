import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import pl from '../locales/pl/messages.json';
import en from '../locales/en/messages.json';

i18n.use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: 'pl',
    preload: ['pl', 'en'],
    resources: {
        pl: {
            translation: pl,
        },
        en: {
            translation: en,
        },
    },
    detection: {
        order: ['path'],
    },
    interpolation: {
        escapeValue: false,
    },
});

i18n.languages = ['pl', 'en'];

export default i18n;
