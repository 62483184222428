import React from 'react';
import { useTranslation } from 'react-i18next';
import Stat from './stat.js';
import Fade from 'react-reveal';
import './intro.scss';

const Intro = () => {
    const [t] = useTranslation();

    return (
        <section className="intro">
            <div className="row">
                <div className="col-12">
                    <Fade bottom>
                        <p className="intro-text">{t('intro.text')}</p>
                    </Fade>
                </div>

                {t('stats', { returnObjects: true }).map(
                    (stat, index) => <Stat key={index} value={stat.value} title={stat.title} text={stat.text}/>,
                )}
            </div>
        </section>
    );
};

export default Intro;
