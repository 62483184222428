import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal';
import Address from './address.js';
import Contact from './contact.js';
import MiniLogo from '../../assets/svg/mini-logo.svg';
import './footer.scss';

const Footer = () => {
    const [t] = useTranslation();

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-content">
                    <div className="row">
                        <div className="col-12">
                            <Fade bottom>
                                <p className="footer-text">{t('footer.text')}</p>
                            </Fade>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="footer-addresses">
                                <div className="row no-gutters">
                                    {t('addresses', { returnObjects: true }).map(
                                        (address, index) =>
                                            <Address key={index} title={address.title} lines={address.lines} tel={address.tel} email={address.email}/>,
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Contact/>
                        </div>
                        <div className="col-12">
                            <Fade bottom>
                                <div className="footer-logo-wrapper">
                                    <MiniLogo className="footer-logo"/>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
