import React, { useState } from 'react';
import cl from 'classnames';
import { useTranslation } from 'react-i18next';

const EmailField = ({ name }) => {
    const [t] = useTranslation();

    const [isFocused, setFocused] = useState(false);
    const [isFilled, setFilled] = useState(false);

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    const handleChange = (e) => {
        setFilled(e.target.value.length > 0);
    };

    return (
        <div className={cl('contact-field-wrapper', {
            focused: isFocused,
            filled: isFilled,
        })}>
            <label className="contact-label" htmlFor={name}>
                {t(`contact.${name}`)}
            </label>
            <input required={true} onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} className="contact-field" type="email" id={name} name={name}/>
        </div>
    );
};

export default EmailField;
