import React, { useState } from 'react';
import cl from 'classnames';
import './contact.scss';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal';
import EmailField from './email-field.js';
import SelectField from './select-field.js';
import TextField from './text-field.js';
import TextareaField from './textarea-field.js';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
    const [t] = useTranslation();
    const [isError, setError] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isSending, setSending] = useState(false);

    const handleSubmit = (e) => {
        setError(false);
        setSuccess(false);
        setSending(true);

        e.preventDefault();
        const data = new FormData(e.target);
        fetch(`${process.env.API_URL}`, { method: 'POST', body: data })
            .then(res => {
                setSending(false);

                if (res.status === 200) {
                    setSuccess(true);
                } else {
                    setError(true);
                }
            });
    };

    return (
        <Fade bottom>
            <div className="contact">
                <form id="contact-form" onSubmit={handleSubmit}>
                    <Fade bottom>
                        <TextField name="name"/>
                    </Fade>

                    <Fade bottom>
                        <SelectField name="sector" options={['sea', 'road', 'hr']}/>
                    </Fade>

                    <Fade bottom>
                        <TextField name="tel"/>
                    </Fade>

                    <Fade bottom>
                        <EmailField name="email"/>
                    </Fade>

                    <Fade bottom>
                        <TextareaField name="message"/>
                    </Fade>

                    <Fade bottom>
                        <div className="contact-item-wrapper">
                            <ReCAPTCHA className="contact-captcha" sitekey={process.env.RECAPTCHA_KEY}/>
                        </div>
                    </Fade>

                    <Fade bottom>
                        <>
                            <div className="contact-item-wrapper">
                                <button disabled={isSending} type="submit" form="contact-form" className={cl('contact-button', {
                                    sending: isSending,
                                })}>
                                    <span className="contact-button-text">{t(isSending ? 'contact.sending' : 'contact.send')}</span>
                                </button>
                            </div>

                            <div className="contact-response-wrapper">
                                <Fade bottom when={isSuccess}>
                                    <p className="contact-response success">
                                        {t('contact.success')}
                                    </p>
                                </Fade>
                                <Fade bottom when={isError}>
                                    <p className="contact-response error">
                                        {t('contact.error')}
                                    </p>
                                </Fade>
                            </div>
                        </>
                    </Fade>
                </form>
            </div>
        </Fade>
    );
};

export default Contact;
