import React from 'react';
import './note.scss';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal';
import EuPl from '../../assets/img/eu-pl@2x.png';
import EuEn from '../../assets/img/eu-en@2x.png';
import FePl from '../../assets/img/fe-pl@2x.png';
import FeEn from '../../assets/img/fe-en@2x.png';
import PlPl from '../../assets/img/pl-pl@2x.png';
import PlEn from '../../assets/img/pl-en@2x.png';

const Note = ({ locale }) => {
    const [t] = useTranslation();

    return (
        <div className="note">
            <div className="container">
                <div className="note-content">
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-6">
                            <Fade bottom>
                                <p className="note-text">{t('note.text')}</p>
                            </Fade>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Fade bottom cascade>
                                <div className="note-partners">
                                    {/* HARDCODE WARNING */}
                                    {locale === 'pl' && (
                                        <Fade bottom>
                                            <img className="note-partners-logo" src={FePl} alt={t('note.fe')}/>
                                            <img className="note-partners-logo" src={PlPl} alt={t('note.pl')}/>
                                            <img className="note-partners-logo" src={EuPl} alt={t('note.eu')}/>
                                        </Fade>
                                    )}

                                    {locale === 'en' && (
                                        <Fade bottom>
                                            <img className="note-partners-logo" src={FeEn} alt={t('note.fe')}/>
                                            <img className="note-partners-logo" src={PlEn} alt={t('note.pl')}/>
                                            <img className="note-partners-logo" src={EuEn} alt={t('note.eu')}/>
                                        </Fade>
                                    )}
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Note;
