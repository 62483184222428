import React, { useState } from 'react';
import cl from 'classnames';
import { useTranslation } from 'react-i18next';

const SelectField = ({ name, options }) => {
    const [t] = useTranslation();
    const [isFilled, setFilled] = useState(false);

    const handleChange = (e) => {
        if (e.target.value === '' || e.target.value === null || e.target.value === undefined) {
            setFilled(false);
        } else {
            setFilled(true);
        }
    };

    return (
        <div className={cl('contact-field-wrapper', {
            filled: isFilled,
        })}>
            <label className="contact-label" htmlFor={name}>
                {t(`contact.${name}`)}
            </label>
            <div className="contact-field-select-symbol">+</div>
            <select onChange={handleChange} required={true} className="contact-field select" id={name} name={name}>
                <option label=" "/>
                {options.map((option, index) => (
                    <option key={index} value={option}>{t(`contact.options.${option}`)}</option>
                ))}
            </select>
        </div>
    );
};

export default SelectField;
