import React from 'react';
import './sector.scss';
import Fade from 'react-reveal/Fade.js';

const Sector = ({ title, list }) => (
    <article className="sector">
        <Fade bottom>
            <h3 className="sector-title">{title}</h3>
            <ul className="sector-list">
                {list.map((item, index) => <li key={index} className="sector-list-item">{item}</li>)}
            </ul>
        </Fade>
    </article>
);

export default Sector;
