import React from 'react';
import './transport.scss';
import Fade from 'react-reveal';

const Transport = ({ icon, title, text }) => {
    const Icon = require(`../../assets/svg/${icon}`);

    return (
        <article className="transport">
            <Fade bottom>
                <div className="transport-wrapper">
                    <Icon className="transport-icon"/>
                    <div className="transport-content">
                        <Fade bottom>
                            <h2 className="transport-title">{title}</h2>
                            <p className="transport-text">{text}</p>
                        </Fade>
                    </div>
                </div>
            </Fade>
        </article>
    );
};

export default Transport;
