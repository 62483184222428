import React, { useEffect, useState } from 'react';
import cl from 'classnames';
import image1 from '../../assets/img/banner-1.jpg';
import image2 from '../../assets/img/banner-2.jpg';
import image3 from '../../assets/img/banner-3.jpg';
import image4 from '../../assets/img/banner-4.jpg';
import image5 from '../../assets/img/banner-1.jpg';
import './images-loop.scss';

const ImagesLoop = () => {
    const speed = 5000;
    const images = [image4, image3, image2, image1];

    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
        if (images.length - currentIndex === 0) {
            setTimeout(() => {
                setCurrentIndex(0);
            }, 500)
        }

        setTimeout(() => {
            setCurrentIndex((currentIndex + 1) % (images.length + 1));
        }, currentIndex === 0 ? speed - 500 : speed);
    }, [currentIndex, images.length]);

    return (
        <div className="images-loop">
            <img src={image5} alt="Nowakowski Transport"/>

            <div className="images-loop-content">
                {images.map((image, index) => (
                    <img
                        key={index}
                        className={cl('images-loop-content-image', {
                            swiped: index >= images.length - currentIndex,
                        })}
                        src={image}
                        alt={'Nowakowski Transport'}
                    />
                ))}
            </div>
        </div>
    );
};


export default ImagesLoop;
