import React, { useEffect, useState } from "react"
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Banner from '../../components/banner/banner.js';
import Footer from '../../components/footer/footer.js';
import Main from '../../components/main/main.js';
import Navbar from '../../components/navbar/navbar.js';
import Note from '../../components/note/note.js';
import OgImg from '../../assets/img/og-image.jpg';
import './app.scss';
import '../../services/i18n.js';

const App = ({ locale, announcments }) => {
    const [t, i18n] = useTranslation();
    const [announcesOpen, setAnnouncesOpen] = useState(false);
    useEffect(() => {
        i18n.changeLanguage(locale);
    }, [i18n, locale]);

    return (
        <>
            <Helmet>
                <html lang={locale}/>
                <title>Nowakowski Transport</title>
                <meta name="description" content={t('banner.text')}/>
                <meta property="og:title" content="Nowakowski Transport"/>
                <meta property="og:description" content={t('banner.text')}/>
                <meta property="og:image" content={OgImg}/>
                <meta property="og:type" content="website"/>
                <meta property="og:locale" content={locale}/>

                <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8296287.js"/>
            </Helmet>

            <header>
                <Navbar announcesOpen={announcesOpen} onAnnouncesContentChange={() => setAnnouncesOpen(prev => !prev)}/>
                <Banner/>
            </header>
            <Main announcements={announcments}/>
            <Footer/>
            <Note locale={locale}/>
        </>
    );
};

export default App;
