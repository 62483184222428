import React from 'react';
import AnimatedNumber from 'animated-number-react';
import Fade from 'react-reveal';

import './stat.scss';

const Stat = ({ value, title, text }) => (
    <article className="stat">
        <Fade bottom>
            <h2 className="stat-title">
            <span className="stat-title-value">
                <AnimatedNumber value={value} formatValue={(value) => value.toFixed(0)}/>
            </span>
                <span className="stat-title-describtion">{title}</span>
            </h2>
            <p className="stat-text">{text}</p>
        </Fade>
    </article>
);

export default Stat;
