import React from 'react';
import './address.scss';
import Fade from 'react-reveal';

const Address = ({ title, lines, tel, email }) => (
    <article className="address">
        <Fade bottom>
            <h2 className="address-title">{title}</h2>
        </Fade>

        <Fade bottom cascade>
            <ul className="address-list">
                {lines.map((line, index) => <li key={index} className="address-list-item">{line}</li>)}
                <li className="address-list-item">
                    <a className="address-list-link" href={`tel:${tel.replace(/\s/g, '')}`}>
                        {tel}
                    </a>
                </li>
                <li className="address-list-item">
                    <a className="address-list-link" href={`mailto:${email}`}>
                        {email}
                    </a>
                </li>
            </ul>
        </Fade>
    </article>
);

export default Address;
