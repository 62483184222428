import React from 'react';
import './main.scss';
import Expeditions from '../expeditions/expeditions.js';
import Intro from '../intro/intro.js';
import Sectors from '../sectors/sectors.js';
import Announcments from "../announcements/announcments"

const Main = ({ announcements }) => (
    <main className="main">
        <div className="container">
            <div className="main-content">
              {
                announcements ? <Announcments/> : (
                  <>
                    <Intro/>
                    <Expeditions/>
                    <Sectors/>
                  </>
                )
              }
            </div>
        </div>
    </main>
);

export default Main;
