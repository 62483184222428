import React from 'react';
import { useTranslation } from 'react-i18next';
import Transport from './transport.js';
import './expeditions.scss';

const Expeditions = () => {
    const [t] = useTranslation();

    return (
        <section className="expeditions">
            <div className="row no-gutters">
                {t('transports', { returnObjects: true }).map(
                    (transport, index) => <Transport key={index} icon={transport.icon} title={transport.title} text={transport.text}/>,
                )}
            </div>
        </section>
    );
};

export default Expeditions;
