import React from "react"
import './annountments.scss';

const Announcments = () => {
  return (
    <article className="announcments">
      <div className={'row'}>
        <div className={'col-12'}>
          <p>ZAPYTANIE OFERTOWE</p>
          <h2>
            NA ZAKUP ROBÓT I MATERIAŁÓW BUDOWLANYCH W CELU BUDOWY HALI MAGAZYNOWEJ
          </h2>
          <p style={{ marginTop: 48 }}>Tytuł projektu:</p>
          <h2>
            „Wsparcie przedsiębiorstwa Nowakowski, negatywnie dotkniętego skutkami Brexit”
          </h2>
          <p style={{ marginBottom: 24, marginTop: 48 }}>
            W ramach programu działań dotyczących wkładu finansowego z Pobrexitowej rezerwy dostosowawczej, zwanego dalej „Programem Re_Open UK”
          </p>

          <div className={'files-container'}>
            <a href="/Zapytanie ofertowe na zakup robot  i materialow.pdf" download>Zapytanie ofertowe na zakup robot  i materialow</a>
            <a href="/Zalacznik 1 - Formularz ofertowy.pdf" download>Załącznik 1 - Formularz ofertowy</a>
            <a href="/Zalacznik 2 - Wykaz wykonanych usług budowlanych.pdf" download>Załącznik 2 - Wykaz wykonanych usług budowlanych</a>
            <a href="/Zalacznik 3 - Umowa o poufności.pdf" download>Załącznik 3 - Umowa o poufności</a>
            <a href="/Zalacznik 4 - Umowa o prace budowlane.pdf" download>Załącznik 4 - Umowa o prace budowlane.pdf</a>
          </div>
        </div>
      </div>
    </article>
  )
}

export default Announcments;
