import React from 'react';
import './banner.scss';
import Fade from 'react-reveal';
import { useTranslation } from 'react-i18next';
import ImagesLoop from './images-loop.js';
import image from '../../assets/img/logo-fundusze@2x.png';

const Banner = () => {
    const [t] = useTranslation();

    return (
        <section className="banner">
            <div className="container">
                <div className="banner-content">
                    <Fade bottom>
                        <h2 className="banner-title">{t('banner.title')}<span className="banner-title-tm">™</span></h2>
                        <p className="banner-text">{t('banner.text')}</p>
                    </Fade>
                    <div className="banner-image-wrapper">
                        <Fade bottom>
                            <ImagesLoop/>
                            <div className="banner-ue-info">
                                <img src={image} alt="Fundusze" className="banner-funds"/>
                                <a target="_blank" className="banner-funds-link" href="/dotacje-na-kapital.pdf">{t('banner.funds.link')}</a>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
