import React from 'react';
import './sectors.scss';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal';
import Alfa from '../../assets/img/alfa@2x.jpg';
import IFS from '../../assets/img/ifs@2x.jpg';
import PISIL from '../../assets/img/pisil@2x.jpg';
import TUV from '../../assets/img/tuv@2x.jpg';
import Sector from './sector.js';

const Sectors = () => {
    const [t] = useTranslation();

    return (
        <section className="sectors">
            <div className="row">
                <div className="col-12">
                    <Fade bottom>
                        <p className="sectors-text">{t('sectors.text')}</p>
                    </Fade>
                </div>
                <div className="col-12">
                    <div className="sectors-gallery">
                        <Fade bottom cascade>
                            <ul className="sectors-gallery-list">
                                <li className="sectors-gallery-list-item">
                                    <img src={Alfa} alt="Alfa logistics family alliance" className="sectors-gallery-image"/>
                                </li>
                                <li className="sectors-gallery-list-item">
                                    <img src={IFS} alt="IFS International Featured Standards" className="sectors-gallery-image"/>
                                </li>
                                <li className="sectors-gallery-list-item">
                                    <img src={PISIL} alt="Polska Izba Spedycji i Logistyki" className="sectors-gallery-image"/>
                                </li>
                                <li className="sectors-gallery-list-item">
                                    <img src={TUV} alt="TUV" className="sectors-gallery-image"/>
                                </li>
                            </ul>
                        </Fade>
                    </div>
                </div>

                {t('sectors.types', { returnObjects: true }).map(
                    (type, index) => <div key={index} className="col-12 col-md-6 col-xl-4">
                        <Sector title={type.title} list={type.list}/>
                    </div>,
                )}
            </div>
        </section>
);
};

export default Sectors;
