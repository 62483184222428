import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/svg/logo.svg';
import cl from 'classnames';
import './navbar.scss';

const Navbar = () => {
    const [t] = useTranslation();

    const [isScrolled, setScrolled] = useState(false);
    const handleScroll = () => {
        setScrolled(window.pageYOffset > 50);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll();
    });

    const handleContactClick = () => {
        window.scrollBy(0, document.querySelector('.contact').getBoundingClientRect().top - 150);
    };

    return (
        <nav className={cl('navbar', {
            'scrolled': isScrolled,
        })}>
            <div className="container">
                <div className="navbar-content">
                    <div className="navbar-logo-wrapper">
                        <a href={'/'} className="navbar-logo-link">
                            <Logo className="navbar-logo"/>
                        </a>
                    </div>

                    <div className="navbar-side">
                        <div className="navbar-languages-wrapper">
                            <ul className="navbar-languages-list">
                                <li className="navbar-languages-item">
                                    <a href={'/'} hrefLang="pl" className="navbar-languages-button">PL</a>
                                </li>
                                <li className="navbar-languages-item">
                                    <a href={'/en'} hrefLang="en" className="navbar-languages-button">EN</a>
                                </li>
                                <li className="navbar-languages-item">
                                    <a style={{  }} href={'/ogloszenia'} className="navbar-languages-button">
                                        OGŁOSZENIA
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="navbar-contact">
                            <button onClick={handleContactClick} tabIndex={isScrolled ? 0 : -1} className="navbar-contact-button">
                                <span className="navbar-contact-button-text">{t('menu.button')}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
